import { createServer, Model } from "miragejs";

export default createServer({
  models: {
    portfolio: Model,
    email: Model,
    artist: Model,
    archive: Model,
  },

  seeds(server) {
    // Seed some portfolios, emails, artists, and archives (optional)
   /// server.create("portfolio", { name: "My Portfolio 1" });
  ////  server.create("portfolio", { name: "My Portfolio 2" });

    server.create("portfolio", {
      id: "3",
      name: "Van Life App",
      description:
        "This React Van Life application uses Mirage JS for a mock server API, ensuring a well-organized development process that prioritizes file and folder structure. Seamless navigation is facilitated by React Router (Router, Route, Routes), with clear route definitions using path and element. For dynamic content, useParams extracts ID parameters from the URL. The application incorporates an authentication system and empowers users to filter van options using the useLocation hook, enabling conditional rendering based on filter selections. CSS styling adds the finishing touch for a visually appealing user experience and responsive web design.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image42.8bccc63376131327bc6c.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp34.29101e09988cbdf8c0c7.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-scrimba-vanlife-zahra",
      website:"https://superb-chimera-306589.netlify.app/",
    });

    server.create("portfolio", {
      id: "4",
      name: "Yoruba Quiz App",
      description:
        "Designed to help users learn Yoruba, this interactive quiz uses React and incorporates Yoruba language data for an immersive experience. Error handling ensures a smooth user journey, while the React Audio Player (npm) allows users to listen to pronunciations of various user languages. Local storage functionality tracks user progress, and the ability to generate random Yoruba words keeps the quiz engaging. Form submission triggers score handling, and CSS stylings visual appealing.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image46.78ce1d08a8ac6bf5a59c.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp38.250c0ed44241010f0e60.jpg",
      type: "React",
      type1: "React, Tailwind",
      github:"https://github.com/zahrabola/my-yoruba-quiz-zahra",
      website:"https://cheery-kangaroo-fd0bb8.netlify.app/",
    });
    server.create("portfolio", {
      id: "5",
      name: "Noughts & crosses App",
      description:
        "This interactive Noughts & Crosses game use Reacts useState and useEffect hooks for state management and potential game conclusion for each player (UserPlayer_X and UserPlayer_O). CSS styling with flexbox and grid layouts creates a visually appealing interface. The game logic incorporates winning conditions by checking rows, columns, and diagonal strikes. A user-friendly experience is ensured with a game over reset functionality.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image45.8bcab2e351802ce80031.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp36.201fcf4bdb74a505891f.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-noughts-crosses-z-app",
      website:"https://splendorous-dolphin-b1c693.netlify.app",
    });
    server.create("portfolio", {
      id: "6",
      name: "Cocktail App",
      description:
        "Designed with user experience in mind, this React cocktail website caters cocktail enthusiast. It utilizes the Cocktail API multiple timed, to provide a vast and informative cocktail library. Material UI styles elevate the visual experience, while a well-organized file and folder structure ensures maintainability. React Router (Router, Route, Routes) empowers users with smooth navigation, and light and dark mode themes offer personalization options. Font Awesome icons with conditional loading pages with responsive design guarantee optimal viewing on any device. The users can navigate to single cocktail pages packed with more detailed information. The interactive and informative search page is the perfect for any cocktail enthusiast.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image40.8c45097708318d9f15c0.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp33.93193cb0c2f136c6cbe8.jpg",
      type: "React",
      type1: "React, Material Ui, Api ",
      github:"https://github.com/zahrabola/my-bar-cocktail-app",
      website:"https://resilient-cajeta-a3459b.netlify.app/",
    
    });
    server.create("portfolio", {
      id: "7",
      name: "Tenzies App",
      description:
        "This Tenzies dice game utilizes React's useState and useEffect hooks for state management. Users can interact with the dice to roll as many times as they like, aiming to get all dice to show the same number. When user eventually wins a congratulations effect of state is confetti celebration.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image44.dfe832a0666470cfb46c.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp37.5a0dbbe6a272392619f6.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-tenzies-scrimba-zahra",
      website:"https://soft-gingersnap-b661ba.netlify.app/",
    
    });
    server.create("portfolio", {
      id: "8",
      name: "Password Generator",
      description:
        "This user-friendly password generator uses security preferences with checkbox-based password creation. The JS interactive copy icon allows seamless transfer of the generated password to your clipboard. For informed decision-making, each password comes with a corresponding strength rating. CSS styling and responsive design ensure a visually appealing and accessible experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image38.e53d060598f0cf0ad827.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp31.1b0b3fd7cad708e74bac.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript ",
      github:"https://github.com/zahrabola/Password-generator",
      website:"https://legendary-sprinkles-18f70f.netlify.app/",
     
    });

    server.create("portfolio", {
      id: "9",
      name: "Morse Code",
      description:
        "This user-friendly Morse Code translator brings functionality and style together. Featuring a Morse code dictionary that handles translation between Morse code and text and incorporates a translate button event listener for user interaction. CSS styling is implemented to enhance the visual experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image43.986fc6ec02fa24375b94.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp39.b3d7642acdeb46126cec.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript",
      github:"https://github.com/zahrabola/Morse-Code",
      website:"https://magnificent-kelpie-763a04.netlify.app/",
    });

    server.create("portfolio", {
      id: "10",
      name: "Dashboard",
      description:
        "This React admin dashboard app prioritizes user experience with a well-organized file and folder structure. I used Material UI styles for a polished look while incorporating React Router (Router, Route, Routes) for seamless navigation. The app offers both light and dark mode themes, and a user-friendly interface with a Navbar and React Pro Sidebar. Data visualization from  Nivo charts and data grids. The Calendar npm keeps users organized to ensure accurate data entry, the app utilizes Formik and Yup validation for the profile section. Overall, this dashboard empowers users with the tools they need for efficient management.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image37.0be82d8a17b0275b860f.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp30.400f83942a95eef44393.jpg",
      type: "React",
      type1: "React, Material Ui",
      github:"https://github.com/zahrabola/my-dash-board-z-app",
      website:"https://candid-hummingbird-48a2d4.netlify.app/",
    });

    server.create("portfolio", {
      id: "11",
      name: "Exercise App",
      description:
        "The Exercise App takes user experience to the next level with a responsive design for seamless access on multiple devices. I used multiple exercise APIs to provide extensive exercise library. An integrated BMI converter empowering a user to track their fitness goals. Utilizing React Router (Router, Route, Routes) for smooth navigation, the website features a unique horizontal scroll bar showcasing body part components. To further enhance user experience, the website provides similar exercise suggestions powered by a YouTube exercise API and implements pagination for efficient browsing. This landing page presenting an interactivity and informative delivery, combined with captivating CSS styling with the functionality.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image25.9287b353743cfd377ae6.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp18.8782e94042b43a06555c.jpg",
      type: "React",
      type1: "React, Api, Material Ui",
      github:"https://github.com/zahrabola/my-gym-z-app",
      website:"https://jazzy-starburst-ebc46d.netlify.app/",
      

    });

    server.create("portfolio", {
      id: "12",
      name: "Shopping Cart E-commerce",
      description:
        "This React E-commerce shopping cart focuses on core concepts like have a well-organized file structure, navigation with React Router (Router, Route, Routes), and state management using a Context object createContext and Context Provider for efficient data sharing with product components. CSS styling adds the finishing touch for a visually appealing user experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image35.823cbf0470e0132f51aa.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp28.c4ca1faa6ea0a536da23.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-e-com-z-app",
      website:"https://beautiful-pegasus-3dab53.netlify.app/",
  
    });

    server.create("portfolio", {
      id: "13",
      name: "Banking App",
      description:
        "This Banking App prioritizes user experience with a responsive design for effective viewing across multiple devices. I Incorporated real-time stock market data using a stock market API and visualized it with a npm line graph, keeping users informed. I meticulously followed a Figma document and used Photoshop for the image visuals, I crafted an interactive and informative landing page, using CSS styling adds the finishing touch, ensuring a visually appealing and user-friendly experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image31.dfe85899ea57bf42d44f.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp25.939b438bc1d5cf186048.jpg",
      type: "React",
      type1: "React, Css, Api",
      github:"https://github.com/zahrabola/my-banking-z-app",
      website:"https://mellifluous-kelpie-9671d1.netlify.app/",
   
    });

    server.create("portfolio", {
      id: "14",
      name: "Cyrpto App",
      description:
        "This React Crypto app is the first time I use pagination, allowing users to navigate through a vast amount of data retrieved from the CoinCap API. It displays information in a well-organized data table and utilizes React hooks for efficient state management.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image26.73b844344ece9cdb7759.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp22.cc9bfd188207e5ca18b6.jpg",
      type: "React",
      type1: "React, Css, Api",
      github:"https://github.com/zahrabola/my-coin-z-app",
      website:"https://mellifluous-licorice-11351b.netlify.app/",
      
   
    });

    server.create("portfolio", {
      id: "15",
      name: "Real Estate App",
      description:
        "This Real Estate website caters to user experience with a responsive design. React Router (Router, Route, Routes) creates seamless navigation, while Email.js npm empowers users to easily connect through a contact form. React icons enhance the user interface, and captivating CSS styling brings the landing page to life. This combination of technical expertise and design excellence ensures a website that effectively showcases properties and connects with potential clients.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image36.b5f711551cd437c8cce5.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp29.5cba4044cf4a1700fa3e.jpg",
      type: "React",
      type1: "React, Boostrap ",
      github:"https://github.com/zahrabola/my-realestate-z-app",
      website:"https://melodious-semolina-cd52ab.netlify.app/",
   
    });
    server.create("portfolio", {
      id: "16",
      name: "Image Editor Website",
      description:
        "This image editors allows users to manipulate photos directly in the browser using HTML, CSS, and JavaScript. It offers various image filters like grayscale, inversion, and saturation adjustments, along with brightness controls. Additionally, users can rotate and flip images, and save their edited creations.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image30.02b497e67505c414518b.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp24.5d42622e6be7a5b6fefc.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript",
      github:"https://github.com/zahrabola/imageEd",
      website:"https://soft-platypus-3d5558.netlify.app/",
   
    });
    server.create("portfolio", {
      id: "17",
      name: "Architecture Blog Website",
      description:
        "This React blog application caters to architecture enthusiasts with a responsive design and smooth navigation through React Router (Router, Route, Routes). I created an engaging image slider, while the interactive writing page allows users to contribute content. The Blog content is easily accessible, and a user settings component with React icons allows for personalization. A convenient side navbar provides quick access to different sections of the website.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image27.973fadc06a95b62751b0.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp20.a4ae0168816c282c71eb.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript ",
      github:"https://github.com/zahrabola/my-blog-z-app",
      website:"https://incandescent-melba-ef51da.netlify.app/",
     
    });
    server.create("portfolio", {
      id: "18",
      name: "Fine Dining Website",
      description:
        "This client focused restaurant website prioritizes user experience with a responsive design that adapts to multiple devices. Using Photoshop for captivating visuals and following a Figma design document, I created an interactive and informative landing page. By combining CSS styling that focuses on usability, bring a client's vision to life.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image28.7784adebaa8c60cf7de9.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp19.19e3e006ed2b2e5d347a.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript",
      github:"https://github.com/zahrabola/my-resturant-z-app",
      website:"https://voluble-licorice-69a33b.netlify.app/",
     
    });
    server.create("portfolio", {
      id: "19",
      name: "Food Blog Website",
      description:
        "This client focused food blog showcases a captivating header carousel showing mouthwatering dishes, while utilizes Bootstrap for a clean and responsive layout, and featuring a classic blog style design. I implemented CSS styling with hover effects, rounded corners, and borders to create a visually appealing and user-friendly experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image11.f723d95ab7eb18ad44c2.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp1.2558f878cbde024b5988.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript, Boostrap ",
      github:"https://github.com/zahrabola/zahra-food-blog",
      website:"https://amazing-clafoutis-a66538.netlify.app/",
      
    });
    server.create("portfolio", {
      id: "20",
      name: "Booking App",
      description:
        "I designed this React Booking app with a user centric approach, featuring a header with an advanced search bar incorporating a calendar using npm react calendar. It streamlines the booking process with intuitive components like Email List, Search Result List Page, and a smooth React Slider for browsing options. React Icons enhance the user interface, and responsive design ensures optimal viewing across devices. Utilizing React Router (Router, Route, Routes), the app seamlessly guides users through the booking journey, complemented by informative homepage listings.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image29.3f74645f11c93440472a.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp21.d16631c9321f78b4990c.jpg",
      type: "React",
      type1: "React, Boostrap",
      github:"https://github.com/zahrabola/my-booking-z-app",
      website:"https://starlit-frangipane-ff4be2.netlify.app/",
      
    });
    server.create("portfolio", {
      id: "21",
      name: "Food Recipe App",
      description:
        "This React food and recipe app are for culinary enthusiasts with its responsive design for on-the-go access. It features a Menu API with a vast recipe selection, complete with search and filter functionalities for personalized exploration. Each recipe includes a Read More/Read Less button for concise information display and clickable ingredient links for convenient shopping. React hooks provide efficient state management, ensuring a seamless and enjoyable user experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image24.39d06ac786cd40c89546.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp14.2aee6bf147cae2a2f443.jpg",
      type: "React",
      type1: "React, Api, Boostrap ",
      github:"https://github.com/zahrabola/my-foodrecipe-z-app",
      website:"https://verdant-trifle-23490f.netlify.app/",
  
    });
    server.create("portfolio", {
      id: "22",
      name: "Countries App",
      description:
        "This React Countries app uses React Router (Router, Route, Routes) for seamless navigation and showcases a responsive design for optimal viewing across multiple devices. It allows users to filter countries and features Read More/Read Less buttons for concise information display. Within this project I use React icons and utilizes a Country API data.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image22.ac62faffe648adb4ccdb.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp17.bbfb4d1ade9c9046434e.jpg",
      type: "React",
      type1: "React, Api, Boostrap",
      github:"https://github.com/zahrabola/my-countries-app",
      website:"https://aquamarine-caramel-672c90.netlify.app/",
  
    });
    server.create("portfolio", {
      id: "23",
      name: "Car Website",
      description:
        "This car website offers a seamless user experience with a responsive design, eye-catching Bootstrap carousel, and interactive JavaScript elements like Read More/Read Lesd buttons and filter functionality, all powered by CSS styling. users can easily control the displayed information allowing for quick and targeted car selection.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image21.78e153cd4882b3b509ab.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp13.b4cf403416618e18468c.jpg",
      type: "Javascript",
      type1: "Html, Css, Javascript",
      github:"https://github.com/zahrabola/car-website",
      website:"https://eclectic-florentine-a41876.netlify.app/",
    });
    server.create("portfolio", {
      id: "24",
      name: "Tour App",
      description:
        "This interactive React Tour utilizes hooks for state management and uses a Tour Guide API to guide users through the interface. It offers a convenient Remove Button for users to dismiss the tour and incorporates CSS styling for a visually appealing experience. Additionally, the 'Read More/Read Less' button provides users with control over the amount of information displayed at each tour step.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image20.03bc8465abd702333254.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp12.6e701e9bfd0b1f7711b5.jpg",
      type: "React",
      type1: "React, Api",
      github:"https://github.com/zahrabola/my-tour-z-app",
      website:"https://courageous-kashata-87785d.netlify.app/",
    });
    server.create("portfolio", {
      id: "25",
      name: "Colour App",
      description:
        "This React colour palette app caters to colour ideologies with its responsive design for optimal usability. It empowers users to search and filter colours, convert between colour spaces, and integrate Font Awesome icons within React for a visually appealing and interactive experience. React hooks ensure efficient state management for a smooth user journey.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image19.e2b5037b90241e7f0298.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image19.e2b5037b90241e7f0298.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-colour-z-app",
      website:"https://elaborate-daffodil-dcc27a.netlify.app/",
      
    });
    server.create("portfolio", {
      id: "26",
      name: "Restaurants Website",
      description:
        "This client-focused restaurant website prioritizes user experience with a responsive design built using Bootstrap. I incorporated CSS hover effects and styled links to enhance the website's visual appeal and user interaction.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image18.d182ffbc6615450d4c9a.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp3.82b6fe8df7713cde9741.jpg",
      type: "Javascript",
      type2: "Html, Css, Javascript",
      github:"https://github.com/zahrabola/barbecue-responsive-website",
      website:"https://dashing-sundae-b4405b.netlify.app/",
    });
    server.create("portfolio", {
      id: "27",
      name: "Budget App",
      description:
        "This client friendly React budget app empowers users to manage their finances with a built-in budget calculator. Using a responsive design for on the go access, uses local storage to save financial data, integrates Font Awesome icons within React for a user-friendly experience, and utilizes React hooks for smooth functionality.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image15.4b2b95d7480ced64e32a.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image15.4b2b95d7480ced64e32a.jpg",
      type: "React",
      type1: "React, Css, Boostrap",
      github:"https://github.com/zahrabola/my-budget-z-app",
      website:"https://reliable-lokum-93587a.netlify.app/",
    });
    server.create("portfolio", {
      id: "28",
      name: "Notes App",
      description:
        "This client focused Notes app built with React allows users to seamlessly add, delete, and save their notes using local storage. It features a responsive design for optimal viewing across devices, incorporates Font Awesome icons for a touch of visual flair, and utilizes React hooks for efficient state management and CRUD functions.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image16.69e4f66983a168a1ada3.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image16.69e4f66983a168a1ada3.jpg",
      type: "React",
      type1: "React, Css, Boostrap",
      github:"https://github.com/zahrabola/my-note-z-app",
      website:"https://dancing-narwhal-7c0aed.netlify.app/",

    });
    server.create("portfolio", {
      id: "29",
      name: "Memory Game App",
      description:
        "This Memory game app uses React hooks for state management and implements engaging game logic. It showcases my skills in CSS styling, animation, and transitions, ensuring a visually appealing and interactive user experience. By incorporating image imports I have created captivating visuals.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image17.a99f143640893ea1bdad.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image17.a99f143640893ea1bdad.jpg",
      type: "React",
      type1: "React, Css",
      github:"https://github.com/zahrabola/my-memo-z-app",
      website:"https://gorgeous-capybara-135de5.netlify.app/",
    });
    server.create("portfolio", {
      id: "30",
      name: "Film App",
      description:
        "This client focused film app utilizes a Film API to showcase movie posters in a responsive scrolling design. It allows users to favourite films using local storage functionality and incorporates Font Awesome icons within React for a visually engaging experience. The app uses CSS hover effects with a responsive design for optimal viewing on any device.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image14.a1c2b90984626389317b.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp8.15412c55a56818277d55.jpg",
      type: "React",
      type1: "React, Api, Css",
      github:"https://github.com/zahrabola/my-memo-z-app",
      website:"https://gorgeous-capybara-135de5.netlify.app/",
    });
    server.create("portfolio", {
      id: "31",
      name: "Travel Website",
      description:
        "This client-focused Travel website, is a responsive design that caters to user engagement with a captivating background video loop, integrates pop-up login and signup functionalities, and offers interactive booking forms. It features a carousel slideshow with controls for seamless navigation to other pages, and a responsive design ensures optimal viewing across devices. I designed a user-friendly image gallery using a grid layout, incorporated blog elements for content creation, and even implemented a dynamic testimonial section with shuffling functionality.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image12.9ae83c6b94d6575daaff.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp4.d542084a3d83b1a5773c.jpg",
      type: "Javascript",
    1: "Html, Css, Javascript",
    github:"https://github.com/zahrabola/my-travel-website",
    website:"https://spectacular-unicorn-59d829.netlify.app/",

    });
  /*  server.create("portfolio", {
      id: "32",
      name: "Quiz App",
      description:
        "This Quiz game uses Quiz API to deliver a dynamic and engaging learning experience. I implemented the game logic to track progress and evaluate answers, while utilizing CSS styling to create a visually appealing interface. The additional feature of categorizing game subjects allows for a more targeted and personalized Quiz experience.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image10.5112bae20461c4ee7bfd.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp4.d542084a3d83b1a5773c.jpg",
      type: "React",
      type1: "React, Api, Css",
      github:"https://github.com/zahrabola/Quiz-App",
      website:"https://vermillion-lebkuchen-918fa7.netlify.app/",

    }); */
    server.create("portfolio", {
      id: "33",
      name: "SheCode Dictionary App",
      description:
        "This dictionary app uses a Dictionary API to provide definitions, phonetics, synonyms, and pronunciations (sound) for searched words. Built with React, it features a responsive design using Bootstrap, integrates Font Awesome icons, and even displays relevant images using the Pexels API.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image6.8630ce5dee9f8890d3fb.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp4.d542084a3d83b1a5773c.jpg",
      type: "React",
      type1: "React, Css, Boostrap, Css",
      github:"https://github.com/zahrabola/my-dictionary-z-app",
      website:"https://stately-marshmallow-1646ce.netlify.app/",

    });
    server.create("portfolio", {
      id: "34",
      name: "SheCode Weather App",
      description:
        "This weather app marks my first introduction into react framework, while I further solidifying my understanding of the OpenWeather API through SheCodes. Creating a responsive design with Bootstrap integration, a dark/light mode toggle, real-time date, and time display, while also featuring an animated weather icon using Reacts animation capabilities.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image5.b2b21a25cf7c972e1a4b.jpg",
      pimageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/fp5.14e4cceac1bafd3bc4b3.jpg",
      type: "React",
      type1: "React, Css, Boostrap, Api",
      github:"https://github.com/zahrabola/my-w-z-app",
      website:"https://sad-shockley-0a2e7b.netlify.app/",
    
    });

    // Seed some emails
    server.create("email", {
      id: "1",
      subject: "Welcome!",
      body: "This is a welcome email.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/coop.48eabb427df3cec6136b.jpeg",
    });
    server.create("email", {
      id: "2",
      subject: "Important Update",
      body: "There's an important update for you.",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/curryandpaxton1.74cb8782653e64d43d30.jpeg",
    });
    server.create("email", {
      id: "3",
      subject: "Important Update 3",
      body: "There's an important update for you. 3",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/curryandpaxton3.7bd5952fd59e85fcd851.jpeg",
    });
    server.create("email", {
      id: "4",
      subject: "Important Update 4",
      body: "There's an important update for you. 4",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/currypaxton2.f4e13b01ffb9fcfc5859.jpeg",
    });
    server.create("email", {
      id: "5",
      subject: "Important Update 5",
      body: "There's an important update for you. 5",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/DogTrust.7874273a3e9f45ea2329.jpeg",
    });
    server.create("email", {
      id: "6",
      subject: "Important Update 6",
      body: "There's an important update for you. 6",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/partyliteFR.998ef3a13046463ce86f.jpeg",
    });
    server.create("email", {
      id: "7",
      subject: "Important Update 7",
      body: "There's an important update for you. 7",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/partyliteUK.2f9e257bd8c6df7f968e.jpeg",
    });
    server.create("email", {
      id: "8",
      subject: "Important Update 8",
      body: "There's an important update for you. 8",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/spacenk.3d9bf05313229039fc7b.jpeg",
    });

    // Seed some artists
    server.create("artist", { name: "Artist 0", id: "0", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/17-img.3e8ac165966e6b3f7d4a.jpg", Title:"House of Pisces: Pandemic",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The multi-generational users complete multiple fishery objectives within the building, including education, leisure and retail therapy, creating a tourist and community-based programme."  });
    server.create("artist", { name: "Artist 1", id: "1", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/2-img.e2504b70413d79e2aa96.jpg", Title:"House of Pisces: Ocean View",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The multi-generational users complete multiple fishery objectives within the building, including education, leisure and retail therapy, creating a tourist and community-based programme."  });
    server.create("artist", { name: "Artist 2", id: "2", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/4-img.afffe100d66075237a3a.jpg", Title:"House of Pisces: Courtyard",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The multi-generational users complete multiple fishery objectives within the building, including education, leisure and retail therapy, creating a tourist and community-based programme."  });
    server.create("artist", { name: "Artist 3", id: "3", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/3-img.bd835b12ae791e26378d.jpg", Title:"House of Pisces: Fishmongers and Fishing school",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The multi-generational users complete multiple fishery objectives within the building, including education, leisure and retail therapy, creating a tourist and community-based programme. "  });
    server.create("artist", { name: "Artist 4", id: "4", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/1-img.ba8cbd12872173600b25.jpg", Title:"House of Pisces: Entrance",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience."  });
    server.create("artist", { name: "Artist 5", id: "5", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/6-img.4db8e4fe7d956f0afaa8.jpg", Title:"House of Pisces: Interior",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The interior render showcases the restaurant kitchen, restaurant, culinary school and auditorium. "  });
    server.create("artist", { name: "Artist 6", id: "6", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/7-img.f8ecdbb53fa16d823795.jpg", Title:"House of Pisces: Section",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. This section reveals the internal and external materials and textures of the building, showcasing surrounding buildings, while revealing the relationships between the different parts of the buildings that are not apparent in the plan drawings."  });
    server.create("artist", { name: "Artist 7", id: "7", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/5-img.b66e2469225252302dbf.jpg", Title:"Namaste Studio and Sanity Plots",  description: "Visualisation of Namaste Studio and Sanity Plots. "  });
    server.create("artist", { name: "Artist 8", id: "8", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/9-img.8880cf8ecef9981f30ec.jpg", Title:"House of Pisces: Exploded Axonometric",  description: "House of Pisces is a fishing cultural centre, exploring and responding to the various contextual aspects of Plymouth’s West Sutton Harbour area, through an educational, creative and interactive spatial experience. The exploded axonometric presents the materiality and structural functions."  });
    server.create("artist", { name: "Artist 9", id: "9", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/8-img.b0a67ce1a2f9a27f4099.jpg", Title:"Namaste Studio and Sanity Plots",  description: "Namaste Studio and Sanity Plots are Wellness and Wellbeing Centres that offers a safe place for those who are suffering with mental health issues. The access to green space, benches and pond within a heavenly urban environment, creates sense of serenity. Namaste Studio and Sanity Plots creates are positive and influencing input into the community, collaborating with various local collaborations that spread awareness on mental health, while providing a haptic architectural environment."  });
    server.create("artist", { name: "Artist 10", id: "10", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/11-img.056e98638652d58a8f03.jpg", Title:"Namaste Studio and Sanity Plots",  description: "Line Drawing of Namaste Studio and Sanity Plots, representing how the community can use the project. "  });
    server.create("artist", { name: "Artist 11", id: "11", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/13-img.72ee3203b8cccfe0080e.jpg", Title:"Namaste Studio and Sanity Plots",  description: "Namaste Studio and Sanity Plots Exploded structural representation of Namaste Studio and Sanity Plots. The axonometric drawing shows the relationship of the structural system and techniques."  });
    server.create("artist", { name: "Artist 12", id: "12", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/15-img.709cedbe9da64fa59135.jpg", Title:"Namaste Studio: Model",  description: "This model was an experimental process that looked at circulation, volumetric design and purposeful design (Sanity plots and garden spaces). I used gelflex mould and plaster as a materiality experiment. To provide an alternative way to map out my design process."  });
    server.create("artist", { name: "Artist 13", id: "13", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/12-img.52518a3400aaa1925b0c.jpg", Title:"Plymouth VR Museum",  description: "Plymouth VR Museum is part of generative architecture creating future tradition through socially and financially sustainable urban hub. The thematic of the building is based around technology and culture. Plymouth VR Museum is a multi- reality museum which educations generations of locals and tourist on the history of Plymouth, creating a community ethos while contributing to the local economy, through revenue."  });
    server.create("artist", { name: "Artist 14", id: "14", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/14-img.20151c0e28f6ec3cd53e.jpg", Title:"Plymouth VR Museum: Site",  description: "Axonometric site and context research based on Plymouth."  });
    server.create("artist", { name: "Artist 15", id: "15", imageartUrl:"https://bejewelled-fairy-f18952.netlify.app/static/media/16-img.00ba388bec60bba89ec6.jpg", Title:"Namaste Studio: Model", semiTile:"Wire Model", description: "This wire sculpture is of my interpretation of mental health. You can't see a person mental health but that doesn't mean it's not real. Mental health can make you second guess everything and feel unworthy of compassion and understanding from others. I wanted to show how the mind and brain is a mystery. What makes somebodies brain tick? Through scientific research chemical imbalances in the brain can lead to abnormal mood, thinking, perception, or behaviour. The wellness and wellbeing centre provide consistent recovery process to help within the community."});

    // Seed some archives
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/image10.5112bae20461c4ee7bfd.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/emailarch3.710e4b7f88d85df9ca1a.jpeg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/emailarch1.6c9a793a943607653809.jpeg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/emailarch2.2419c25f44283ab4b8d3.jpeg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive13.fa1fd697e114d2e6f090.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive12.0c7f78e796abdd6313e2.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive11.cc3480eb0d2e5383af0c.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive10.f7e4e7669f14cb1f801b.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive9.9c45548dbbf4331a1336.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive8.0d050abf5d3dbb33b218.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive7.32f2022704267a18c7ce.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive6.e812ce2241dec86e9b11.jpg",
    });
  
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive4.40a0dd0c48e345d5920f.jpg",
    });
    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive3.dc8aec99d7d6c6773799.jpg",
    });

    server.create("archive", {
      content: "Some archived data",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive1.a3ec10ad32e82f09dc11.jpg",
    });
    server.create("archive", {
      content: "Another archived item",
      imageUrl:
        "https://bejewelled-fairy-f18952.netlify.app/static/media/archive2.56292374c99a5a98590a.jpg",
    });
  },

  routes() {
    this.namespace = "api";

    // Portfolio routes
    this.get("/portfolios", (schema) => {
      return schema.portfolios.all();
    });

    this.get("/portfolios/:id", (schema, request) => {
      const id = request.params.id;
      return schema.portfolios.find(id);
    });

    // Email routes
    this.get("/emails", (schema) => {
      return schema.emails.all();
    });

    this.get("/emails/:id", (schema, request) => {
      const id = request.params.id;
      return schema.emails.find(id);
    });

    // Artist routes
    this.get("/artists", (schema) => {
      return schema.artists.all();
    });

    this.get("/artists/:id", (schema, request) => {
      const id = request.params.id;
      return schema.artists.find(id);
    });

    // Archive routes
    this.get("/archives", (schema) => {
      return schema.archives.all();
    });

    this.get("/archives/:id", (schema, request) => {
      const id = request.params.id;
      return schema.archives.find(id);
    });
  },
});
